<template>
  <div>

    <b-nav-item-dropdown
    class="dropdown-notification mr-25 margin_icon"
    menu-class="dropdown-menu-media"
    right

    v-if="!isMobile"
   
  >
    <template #button-content>

      <b-link class="icon_dashboard"   @click="openNotificaciones"> 

        <b-avatar
        size="35"
      
        :badge="countNotificaciones"
        badge-classes="bg-danger"
        variant="light-primary"
        style="cursor:pointer;">

        <span class="d-flex align-items-center">
   

          <feather-icon
      class="color_icon"
        icon="BellIcon"
        size="18"
      />
        </span>

        </b-avatar>

        </b-link>

  
    </template>

    <!-- Header -->
    <li style="margin-top: 10px;">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto" style="font-weight: bold;font-size: 14px;">
          <feather-icon
                     
                     icon="BellIcon"
                     size="16"
                     
                   
                     />  Notificaciones
        </h4>

        <hr>

     

      </div>
    </li>


    <div v-if="loading">

        <b-row  class="sesiones2">
            
            <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="    padding: 0;display: flex;">

              <b-skeleton style="margin: auto;" type="avatar"></b-skeleton>

           
            </b-col>

             <b-col cols="10" sm="10" md="10" lg="10" xl="10">

              <b-skeleton width="80%"  height="10px"></b-skeleton>
              <b-skeleton width="50%"  height="10px"></b-skeleton>

              <b-skeleton width="40%"  height="10px"></b-skeleton>

            </b-col>
            
            </b-row>

        <b-row  class="sesiones2">
            
            <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="    padding: 0;display: flex;">

              <b-skeleton style="margin: auto;" type="avatar"></b-skeleton>

           
            </b-col>

             <b-col cols="10" sm="10" md="10" lg="10" xl="10">

              <b-skeleton width="80%"  height="10px"></b-skeleton>
              <b-skeleton width="50%"  height="10px"></b-skeleton>

              <b-skeleton width="40%"  height="10px"></b-skeleton>

            </b-col>
            
          </b-row>

          <b-row  class="sesiones2">
            
            <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="    padding: 0;display: flex;">

              <b-skeleton style="margin: auto;" type="avatar"></b-skeleton>

           
            </b-col>

             <b-col cols="10" sm="10" md="10" lg="10" xl="10">

              <b-skeleton width="80%"  height="10px"></b-skeleton>
              <b-skeleton width="50%"  height="10px"></b-skeleton>

              <b-skeleton width="40%"  height="10px"></b-skeleton>

            </b-col>
            
          </b-row>


    </div>

    <b-row v-else-if="codeNotificaciones200">

 

  
      <b-col class="sesiones3" cols="12" sm="12" md="12" xl="12" lg="12" v-for="notificacion in notificaciones" :key="notificacion.id" style="margin-bottom:10px">

        <b-link :to="{ name: notificacion.name, hash: notificacion.hash}" >

          <b-row>

            <b-col cols="2" sm="2" md="2" xl="2" lg="2" style="display:flex">

              <b-avatar
                        size="40"
                        variant="light-primary"
                        style="cursor:pointer; margin:auto"
                        >

                        <span class="d-flex align-items-center">
                        
                          <feather-icon
                     
                                          :icon="notificacion.icon"
                                          size="20"
                                          class="color_icon"
                                        
                                          /> 

                        </span>

                        </b-avatar>

            </b-col>

            <b-col cols="10" sm="10" md="10" xl="10" lg="10">

              <p class="media-heading" style="color: #000 !important; margin-bottom: 0px;">
            <span class="font-weight-bolder">
              {{ notificacion.title }}
            </span>
          </p>
          <small class="notification-text">{{ notificacion.description }}</small>
          <p style="margin:0px" class="notification-text">{{ notificacion.dateRegister }}</p>

          </b-col>

          </b-row>

      </b-link>
        
      </b-col>



      <b-col cols="12" sm="12" md="12" xl="12" lg="12" v-if="manyNotifications" style="padding:0px">

     

      <b-button
      variant="primary"
      block
      size="sm"
      style="border-radius:0px !important"
      :to="{name:'notificaciones'}"
    >Ver más notificaciones</b-button>

  </b-col>
  </b-row>

    <div v-else-if="emptyNotificaciones" align="center" style="margin-top: 15px;">

      <svg  style="width: 50px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 480.61 480.61" ><g><path d="M469.012 20.343l-2.395-6.339-6.339-2.394C439.878 3.906 418.436.0 396.547.0c-48.104.0-93.33 18.733-127.346 52.749l-48.227 48.227-107.787 5.228-90.214 90.213 77.783 24.777-28.763 28.762 16.802 16.802-37.224 57.056 105.235 105.237 57.057-37.224 16.802 16.802 28.763-28.763 24.775 77.783 90.216-90.214 5.227-107.786 48.227-48.227C477.832 161.462 493.98 86.459 469.012 20.343zM79.029 182.788l47.182-47.181 63.197-3.065-64.773 64.773L79.029 182.788zM160.805 390.623l-70.806-70.806 20.473-31.381 81.713 81.714L160.805 390.623zM345.015 354.412l-47.181 47.181-14.527-45.606 64.773-64.773L345.015 354.412zM230.665 366.202 114.419 249.956 290.414 73.961C318.764 45.612 356.455 30 396.547 30c15.957.0 31.625 2.487 46.671 7.401 17.451 53.48 3.463 112.785-36.558 152.807L230.665 366.202z"/><path d="M364.951 70.67c-12.02.0-23.32 4.681-31.819 13.18-17.546 17.545-17.546 46.094.0 63.64 8.499 8.499 19.8 13.18 31.819 13.18s23.32-4.681 31.819-13.18c17.546-17.545 17.546-46.094.0-63.64C388.272 75.351 376.971 70.67 364.951 70.67zM375.558 126.277c-2.833 2.833-6.6 4.394-10.606 4.394s-7.773-1.561-10.606-4.393c-5.849-5.849-5.849-15.365.0-21.214 2.833-2.833 6.6-4.393 10.606-4.393s7.773 1.56 10.606 4.393C381.406 110.912 381.406 120.429 375.558 126.277z"/><path d="M.013 375.612l33.999-34 21.213 21.213-33.999 33.999L.013 375.612zM105.004 480.61l-21.213-21.213 33.999-33.999 21.213 21.213L105.004 480.61zM25.031 432.878l50.122-50.122 21.213 21.213-50.122 50.122L25.031 432.878z"/></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>
  
  <p style="color:#000 !important">
    No tiene notificaciones disponibles
                </p>



    </div>


  </b-nav-item-dropdown>

  <b-link v-else class="icon_dashboard" :to="{ name: 'notificaciones'}"> 

      <b-avatar
      size="35"

      :badge="countNotificaciones"
      badge-classes="bg-danger"
      variant="light-primary"
      style="cursor:pointer;">

      <span class="d-flex align-items-center">


        <feather-icon
      class="color_icon"
      icon="BellIcon"
      size="18"
      />
      </span>

      </b-avatar>

      </b-link>

  </div>
  
</template>

<script>
import {
  BNavItemDropdown, BBadge, BLink, BAvatar, BButton, BSkeleton, BRow, BCol, 
} from 'bootstrap-vue'

export default {
  components: {
    BNavItemDropdown,
    BSkeleton,
    BBadge,
    BLink,
    BAvatar,
    BRow, BCol,
    BButton,
  },
  props: ['userId','tokenAuth','isMobile'],
  data() {
    /* eslint-disable global-require */


  

    return {

      countNotificaciones:'0',
      loading: true,
      notificaciones: [],
      isSearch:true,
      codeNotificaciones200:false,
      manyNotifications:false,

    }
  },created(){
    
  },
    mounted(){

      this.getCountNotifications();
   
  },  methods: {

    openNotificaciones(){

    if(this.isSearch){

      this.loading=true;
      this.codeNotificaciones200=false;
      this.isSearch=false;
      this.emptyNotificaciones=false;



        this.$https.post('/profile/getNotificaciones/', { tokenAuth: this.tokenAuth, userId: this.userId }).then(response => {



        if (response.data.code == 200) {

          
        
         
          this.notificaciones= response.data.notificaciones;
          this.loading=false;

          if(Number(this.notificaciones.length) > Number("0")){

            this.codeNotificaciones200=true;
            this.emptyNotificaciones=false;
          }else{

            this.codeNotificaciones200=false;
            this.emptyNotificaciones=true;

          }

          if(Number(response.data.count) > Number("3")){
            this.manyNotifications=true;
          }
       
         

        } else {

        this.$toast.error(response.data.message, {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })


        if (response.data.code == 401) {
        
        localStorage.removeItem('userData')








        
        

        // Redirect to login page
        this.$router.push({ name: 'auth-login' })
        } else {
        this.getNotifications();
        }
        }
        }).catch(error => {
        this.getNotifications();
        })



    }

    },
    getCountNotifications(){

 
this.$https.post('/profile/getCountNotifications/', { userId:  this.userId }).then(response => {
    

   
  if (response.data.code == 200) {



      this.countNotificaciones= response.data.count;

  

  } else {


    if (response.data.code == 401) {

      this.$toast.error(response.data.message, {
        position: 'top-right',
        timeout: 3010,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
      
      localStorage.removeItem('userData')



      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    } else {
       this.getNotifications();
    }
  }
}).catch(error => {
     this.getNotifications();
})


}


  
}
}
</script>

<style>

</style>
